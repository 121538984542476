import React from 'react';
import { Typography, Paper, Container, Grid, Avatar, List, ListItem, ListItemIcon, ListItemText, Link } from '@mui/material'; // Import Link
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import GitHubIcon from '@mui/icons-material/GitHub'; // Import GitHub icon
import './ProfilePage.css'; // Ensure the path to your CSS file is correct

const ProfilePage = () => {
    return (
        <Container>
            <Paper elevation={3} className="profileContainer">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                        {/* Profile Image */}
                        <Avatar
                            alt="Chris"
                            src={`${process.env.PUBLIC_URL}/chris-profile.jpg`}
                            style={{ width: '200px', height: '200px', margin: 'auto' }}
                        />
                        <Typography variant="h6" style={{ marginTop: '20px' }}>
                            Chris Austin
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {/* Profile Details */}
                        <Typography variant="body1" paragraph>
                            Welcome to my profile! I am a Senior Data Engineer with a passion for building scalable data processing systems.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            With over 7 years of experience in the industry, I have developed a keen understanding of data analytics, machine learning model deployment, and cloud infrastructure.
                        </Typography>
                        {/* Contact and Other Information */}
                        <List className="infoList">
                            <ListItem className="infoListItem">
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Email: ceaustin117@gmail.com" />
                            </ListItem>
                            <ListItem className="infoListItem">
                                <ListItemIcon>
                                    <WorkIcon />
                                </ListItemIcon>
                                <ListItemText primary="Most Recent Employer: Wavicle Data Solutions" />
                            </ListItem>
                            <ListItem className="infoListItem">
                                <ListItemIcon>
                                    <SchoolIcon />
                                </ListItemIcon>
                                <ListItemText primary="Education: Bachelors's in Computer Science and Math" />
                            </ListItem>
                            {/* GitHub Section */}
                            <ListItem className="infoListItem">
                                <ListItemIcon>
                                    <GitHubIcon />
                                </ListItemIcon>
                                {/* Updated GitHub Link */}
                                <ListItemText>
                                    <Link href="https://github.com/ceaustin117" target="_blank" rel="noopener noreferrer">
                                        github.com/ceaustin117
                                    </Link>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default ProfilePage;
