// src/components/DemoDetailPage.js
import React, { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { signIn } from 'aws-amplify/auth';

const ModelSuite = lazy(() => import('./demos/ModelSuite'));
const ETLPipeline = lazy(() => import('./demos/ETLPipeline'));
const GeofenceEvents = lazy(() => import('./demos/GeofenceEvents'));

const demoComponents = {
  '1': ModelSuite,
  '2': ETLPipeline,
  '3': GeofenceEvents,
};

const DemoDetailPage = () => {
  let { demoId } = useParams();

  const DemoComponent = demoComponents[demoId] || (() => <div>Not Found</div>);

  return (
    <Suspense fallback={<Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>}>
      <DemoComponent />
    </Suspense>
  );
};

export default DemoDetailPage;
