import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Alert  } from '@mui/material';
import { signIn, signOut, signUp } from 'aws-amplify/auth';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400, // Adjust width as needed
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const SignInModal = ({ open, handleClose, isSignedIn, setIsSignedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(''); // State to hold error messages
  const [phone_number, setPhoneNumber] = useState('');
  const [view, setView] = useState('signIn'); // Manage view state to toggle between sign-in and sign-up

  const handleSignIn = async () => {
    try {
      await signIn({ username, password });
      setIsSignedIn(true);
      setUsername(username);
      handleClose();
    } catch (error) {
      console.error('Error signing in:', error);
      setError('Failed to sign in. Please try again.'); // Update error state
    }
  };

  const handleSignUp = async () => {
    try {
      const { user } = await signUp({
        username,
        password,
        attributes: {
          email: username,
        }
      });
      if (user) {
        console.log('Sign up successful', user);
        setView('signIn'); // Switch to the sign-in view
        setError(''); // Clear any existing error messages
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError('Failed to sign up. Please try again.'); // Update error state
    }
  };

  const renderSignInView = () => (
    <>
      <Typography variant="h6">Sign In</Typography>
      <TextField label="Username" value={username} onChange={e => setUsername(e.target.value)} fullWidth />
      <TextField label="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} fullWidth />
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      <Button onClick={handleSignIn} variant="contained" sx={{ mt: 2 }}>Sign In</Button>
      <Button onClick={() => setView('signUp')} color="primary" sx={{ mt: 2 }}>Need an account? Sign Up</Button>
    </>
  );

  const renderSignUpView = () => (
    <>
      <Typography variant="h6">Sign Up</Typography>
      <TextField label="Email" type="email" value={username} onChange={e => setUsername(e.target.value)} fullWidth autoFocus />
      <TextField label="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} fullWidth />
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      <Button onClick={handleSignUp} variant="contained" sx={{ mt: 2 }}>Sign Up</Button>
      <Button onClick={() => setView('signIn')} color="primary" sx={{ mt: 2 }}>Already have an account? Sign In</Button>
    </>
  );

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={modalStyle}>
        {view === 'signIn' ? renderSignInView() : renderSignUpView()}
      </Box>
    </Modal>
  );
};

export default SignInModal;
