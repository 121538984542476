import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import { Container, AppBar, Toolbar, Typography,Button, IconButton, Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { signOut } from 'aws-amplify/auth'; // Import signOut from AWS Amplify
import ProfilePage from './components/ProfilePage';
import DemosPage from './components/DemosPage';
import DemoDetailPage from './components/DemoDetailPage';
import EndpointsList from './components/EndpointsList';
import FilesList from './components/FilesList';
import SignInModal from './components/SignInModal';
import { Authenticator } from '@aws-amplify/ui-react';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

const getCurrentUserInfo = async () => {
  try {
    const currentUser = await getCurrentUser();
    const attributes = await fetchUserAttributes(currentUser);
    const username = attributes.email; // Example, adjust based on your attribute key

    return {
      id: currentUser.userId,
      username,
      attributes
    };
  } catch (error) {
    console.error("Failed to get current user info:", error);
    return null;
  }
};


function UserIcon({ isSignedIn, username, setModalOpen, handleSignOut }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* Text displaying sign-in status */}
      <Typography variant="body1" style={{ marginRight: '10px' }}>
        {isSignedIn ? `Signed in as username: ${username}` : 'Not signed in'}
      </Typography>

      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isSignedIn ? (
          <MenuItem onClick={() => {
            handleSignOut(); // Invoke handleSignOut
            handleClose(); // Close the menu
          }}>Sign Out</MenuItem>
        ) : (
          <MenuItem onClick={() => {
            setModalOpen(true);
            handleClose(); // Close the menu
          }}>Sign In</MenuItem>
        )}
      </Menu>
    </div>
  );
}

function App() {
  // Initialize isSignedIn based on localStorage value
  const [isSignedIn, setIsSignedIn] = useState(() => {
    return localStorage.getItem('isSignedIn') === 'true';
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [username, setUsername] = useState('');

  // Effect to update localStorage when isSignedIn changes
  useEffect(() => {
    localStorage.setItem('isSignedIn', isSignedIn);
  }, [isSignedIn]);

  useEffect(() => {
    const checkUserSignedIn = async () => {
      const isUserSignedIn = localStorage.getItem('isSignedIn') === 'true';
      setIsSignedIn(isUserSignedIn);
      
      if (isUserSignedIn) {
        try {
          const userInfo = await getCurrentUserInfo();
          if (userInfo && userInfo.username) {
            setUsername(userInfo.username);
          } else {
            // Handle case where user info couldn't be fetched
            console.error("User info could not be fetched.");
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      }
    };
  
    checkUserSignedIn();
  }, []);

    useEffect(() => {
    // Periodically check sign-in status
    const intervalId = setInterval(async () => {
      try {
        const userInfo = await getCurrentUserInfo();
        if (userInfo && userInfo.username) {
          setUsername(userInfo.username);}
        //
      } catch (error) {
      }
    }, 3000); // 3000 ms = 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);
  console.log(username)

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsSignedIn(false); // Update sign-in status upon successful sign-out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <Authenticator.Provider>
    <Router>
      <div style={{ backgroundColor: 'lightgray', minHeight: '100vh' }}>
        <AppBar position="static" style={{ width: '100%' }}>
          <Toolbar>
            <NavLink to="/" style={{ color: 'inherit', textDecoration: 'none', marginRight: '20px' }}>
              <Button color="inherit">Profile</Button>
            </NavLink>
            <NavLink to="/demos" style={{ color: 'inherit', textDecoration: 'none' }}>
              <Button color="inherit">Demos</Button>
            </NavLink>
            {/* Updated UserIcon to use handleSignOut function */}
            <div style={{ marginLeft: 'auto' }}>
            <UserIcon isSignedIn={isSignedIn} setUsername={setUsername} setModalOpen={setModalOpen} username={username} handleSignOut={handleSignOut} />

            </div>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" style={{ marginTop: '20px' }}>
          <Routes>
            <Route path="/" element={<ProfilePage />} />
            <Route path="/demos" element={<DemosPage />} />
            <Route path="/demos/:demoId" element={<DemoDetailPage />} />
            <Route path="/endpoints" element={<EndpointsList />} />
            <Route path="/files" element={<FilesList />} />
          </Routes>
        </Container>
        <SignInModal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          isSignedIn={isSignedIn}
          setIsSignedIn={setIsSignedIn}
        />
      </div>
    </Router>
    </Authenticator.Provider>
  );
}

export default App;
