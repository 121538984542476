import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Modal, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SageMakerRuntimeClient, InvokeEndpointCommand } from "@aws-sdk/client-sagemaker-runtime";
import { SageMakerClient, ListEndpointsCommand } from "@aws-sdk/client-sagemaker";

import AWS from 'aws-sdk';
import * as StepFunctions from "aws-sdk/clients/stepfunctions";
import { SageMakerRuntime } from '@aws-sdk/client-sagemaker-runtime';
require('../../node_modules/aws-sdk/clients/sagemaker')
// Configure AWS SDK with your AWS credentials
// Configure AWS SDK with your AWS credentials
const config = {
  credentials: {
  accessKeyId: 'AKIA477PMLZETCDYTFHW',
  secretAccessKey: 'O6ZQ+ggO/nvgB6SFeKuH/W8VXp0dakh6jd3AEPnE',
},
region: 'us-east-1'}

const sagemaker_client = new SageMakerClient(config);
const client = new SageMakerRuntimeClient(config);

 

const EndpointsList = () => {
  const [endpoints, setEndpoints] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  const [inputData, setInputData] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [responseBody, setResponseBody] = useState([]);
  const [openResponseModal, setOpenResponseModal] = useState(false);

  useEffect(() => {
    const fetchEndpoints = async () => {
      try {
        const paramListEndpoints = {
          NameContains: "job"
        }
        const commandListEndpoints = new ListEndpointsCommand(paramListEndpoints);
        const response = await sagemaker_client.send(commandListEndpoints);
        const endpointList = response.Endpoints || [];
        setEndpoints(endpointList);
      } catch (error) {
        console.error('Error fetching endpoints:', error);
      }
    };
    fetchEndpoints();
  }, []);

  const handleRowClick = (endpoint, index) => {
    setSelectedEndpoint(endpoint);
    setSelectedRow(index);
    setOpenModal(true);
  };

  const handleCallEndpoint = async () => {
    try {
      const lines = inputData.split('\n');
      const csvData = lines.map(line => {
        const values = line.split(',');
        const csvLine = values.map(value => `"${value}"`).join(',');
        return csvLine;
      }).join('\n');
      const params = {
        EndpointName: selectedEndpoint.EndpointName,
        Body: csvData,
        ContentType: 'text/csv'
      };
      const command = new InvokeEndpointCommand(params);
      const response = await client.send(command);
      setResponseBody(response.Body);
      setOpenResponseModal(true);
      setOpenModal(false);
    } catch (error) {
      console.error('Error calling endpoint:', error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  const handleCloseResponseModal = () => {
    setOpenResponseModal(false);
    setSelectedRow(null); // Reset selected row index when response modal is closed
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {endpoints.map((endpoint, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(endpoint, index)}
                style={{ backgroundColor: selectedRow === index ? 'blue' : 'inherit', cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row">
                  {endpoint.EndpointName}
                </TableCell>
                <TableCell align="right">-</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Paper
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            padding: 20,
          }}
        >
          <IconButton
            style={{ position: 'absolute', top: 5, right: 5 }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
          <h2 id="modal-title" style={{ color: 'blue' }}>Call Endpoint</h2>
          <p id="modal-description" style={{ color: 'blue' }}>Enter input data to call the endpoint: {selectedEndpoint?.EndpointName}</p>
          <TextField
            label="Input Data"
            fullWidth
            multiline
            value={inputData}
            onChange={(e) => setInputData(e.target.value)}
            variant="outlined"
            style={{ marginBottom: 20 }}
          />
          <Button variant="contained" color="primary" onClick={handleCallEndpoint}>Call Endpoint</Button>
        </Paper>
      </Modal>

      <Modal
        open={openResponseModal}
        onClose={handleCloseResponseModal}
        aria-labelledby="modal-response-title"
        aria-describedby="modal-response-description"
      >
        <Paper
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            padding: 20,
            overflowY: 'auto' // Make the modal content scrollable
          }}
        >
          <IconButton
            style={{ position: 'absolute', top: 5, right: 5 }}
            onClick={handleCloseResponseModal}
          >
            <CloseIcon />
          </IconButton>
          <h2 id="modal-response-title" style={{ color: 'blue' }}>Response Body</h2>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(responseBody).map((value, index) => (
                  <TableRow key={index}>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        </Paper>
      </Modal>
    </>
  );
};  

export default EndpointsList;