import React, { useEffect } from 'react';
import { Typography, Paper, List, ListItem, ListItemText, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react'; // Import withAuthenticator
import {Amplify} from 'aws-amplify'; // Import Amplify
import awsconfig from '../aws-exports'; // Import AWS configuration from aws-exports.js
Amplify.configure(awsconfig);

const DemosPage = () => {
  const demos = [
    { id: 1, name: 'ModelSuite', description: 'ModelSuite - An interactive Demo that demonstrates automated Sagemaker Model Creation for Inference' },
    { id: 2, name: 'ETL Pipeline', description: 'An ETL pipeline that aggregates data from multiple sources, processes it, and stores it in a data warehouse for analytics.' },
    { id: 3, name: 'GeofenceEvents', description: 'A dashboard built with React and D3.js to visualize key metrics and trends from a dataset.' },
  ];



  return (
    <Paper elevation={3} sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Project Demos
      </Typography>
      <List>
        {demos.map((demo) => (
          <ListItem key={demo.id} button component={Link} to={`/demos/${demo.id}`}>
            <ListItemText primary={demo.name} secondary={demo.description} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default DemosPage; // Wrap DemosPage with withAuthenticator
